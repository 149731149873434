/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import navbarimg from "../../assets/images/top-removebg.png";
const Navigation = ({ isOpen, currentRoute }) => {
  const navigate = useNavigate();

  const LogOut = () => {
    Cookies.remove("token", { path: "/" });
    Cookies.remove("agentDetails", { path: "/" });
    Cookies.remove("userDetails", { path: "/" });
    Cookies.remove("agentData", { path: "/" });
    Cookies.remove("userdata", { path: "/" });
    localStorage.removeItem("agentData");
    localStorage.removeItem("userdata");
    navigate("/");
  };
  return (
    <header className="header">
      <div className="header__logo">
        <img src={navbarimg} alt="Logo" />
      </div>
      <nav className="header__nav">
        <ul>
          <li>About US</li>|<li>MGM</li>|<li>Tutorials</li>|<li>FAQ</li>|
          <li>Downloads</li>|<li>Contact Us</li>
        </ul>
      </nav>
      <div className="header__actions" style={{ position: "relative" }}>
        <ul>
          <li onClick={() => navigate("/dashboard")}>Manage My Points</li>|
          <li onClick={() => navigate("/child-registration")}>
            Child Registration
          </li>
          |<li onClick={() => navigate("/change-password")}>Change Password</li>
          |<li onClick={() => navigate("/change-pin")}>PIN</li>|
          <li onClick={() => navigate("/update-profile")}>Update Profile</li>|
          <li onClick={() => navigate("/drew-details")}>Draw Details</li>|
          <li onClick={() => navigate("#")}>Download Patch</li>|
          <li onClick={() => navigate("#")}>Play Games</li>|
          <li onClick={() => navigate("/pin-password")}>Pin & Password</li>|
          <li onClick={LogOut}>Logout</li>
        </ul>
      </div>
    </header>
  );
};

export default Navigation;
